import { ElementType, MouseEventHandler } from "react";
import styled, { CSSProperties } from "styled-components";
import {
  ColorProps,
  FlexProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  color,
  flex,
  space,
  typography,
} from "styled-system";

import { ThemeBreakpoints } from "@/types";

export interface CustomTypographyProps
  extends TypographyProps,
    SpaceProps,
    ColorProps,
    FlexProps,
    LayoutProps {
  as?: ElementType;
  htmlFor?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  id?: string | number;
  mdVarient?: TypographyVarient;
  mdWeightVarient?: TypographyWeightVarient;
  className?: string;
  varient?: TypographyVarient;
  weightVarient?: TypographyWeightVarient;
  href?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  navText?: string;
}

export type TypographyWeightVarient = "regular" | "medium" | "semibold" | "bold";

export type TypographyVarient =
  | "displayl"
  | "displays"
  | "heading1"
  | "heading2"
  | "heading3"
  | "heading4"
  | "heading5"
  | "heading6"
  | "bodyl"
  | "bodym"
  | "bodys"
  | "bodyxs"
  | "bodyxxs";

const typographyMDObj = {
  variant: {
    displayl: "4.5rem",
    displays: "4rem",
    heading1: "3rem",
    heading2: "2.5rem",
    heading3: "2rem",
    heading4: "1.75rem",
    heading5: "1.5rem",
    heading6: "1.25rem",
    bodyl: "1.125rem",
    bodym: "1rem",
    bodys: "0.875rem",
    bodyxs: "0.75rem",
    bodyxxs: "0.675rem",
  },
  lineHeight: {
    displayl: "5rem",
    displays: "4.5rem",
    heading1: "3.5rem",
    heading2: "3rem",
    heading3: "2.5rem",
    heading4: "2.25rem",
    heading5: "2rem",
    heading6: "1.75rem",
    bodyl: "1.75rem",
    bodym: "1.5rem",
    bodys: "1.25rem",
    bodyxs: "1rem",
    bodyxxs: "1rem",
  },
  weightVariant: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  letterSpacing: {
    displayl: "-0.02em",
    displays: "-0.02em",
    heading1: "-0.02em",
    heading2: "-0.02em",
    heading3: "-0.02em",
    heading4: "0em",
    heading5: "0em",
    heading6: "0em",
    bodyl: "0em",
    bodym: "0em",
    bodys: "0em",
    bodyxs: "0em",
    bodyxxs: "0em",
  },
};

const typographyXSObj = {
  variant: {
    displayl: "2.5rem",
    displays: "2.25rem",
    heading1: "2rem",
    heading2: "1.875rem",
    heading3: "1.5rem",
    heading4: "1.375rem",
    heading5: "1.25rem",
    heading6: "1.125rem",
    bodyl: "1rem",
    bodym: "0.875rem",
    bodys: "0.75rem",
    bodyxs: "0.75rem",
    bodyxxs: "1rem",
  },
  lineHeight: {
    displayl: "3rem",
    displays: "2.75rem",
    heading1: "2.5rem",
    heading2: "2.5rem",
    heading3: "2rem",
    heading4: "1.75rem",
    heading5: "1.75rem",
    heading6: "1.5rem",
    bodyl: "1.5rem",
    bodym: "1.25rem",
    bodys: "1rem",
    bodyxs: "1rem",
    bodyxxs: "1rem",
  },
  weightVariant: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  letterSpacing: {
    displayl: "-0.02em",
    displays: "-0.02em",
    heading1: "-0.02em",
    heading2: "-0.02em",
    heading3: "0em",
    heading4: "0em",
    heading5: "0em",
    heading6: "0em",
    bodyl: "0em",
    bodym: "0em",
    bodys: "0em",
    bodyxs: "0em",
    bodyxxs: "0em",
  },
};

const typeVariant = (variant: TypographyVarient, breakpoint?: ThemeBreakpoints) => {
  if (breakpoint === ThemeBreakpoints.md) {
    return typographyMDObj.variant[variant] || "1rem";
  }
  return typographyXSObj.variant[variant] || "1rem";
};

const typeLineHeight = (variant: TypographyVarient, breakpoint?: ThemeBreakpoints) => {
  if (breakpoint === ThemeBreakpoints.md) {
    return typographyMDObj.lineHeight[variant] || "1rem";
  }
  return typographyXSObj.lineHeight[variant] || "1rem";
};

const typeWeightVariant = (weightVariant: TypographyWeightVarient) => {
  return typographyMDObj.weightVariant[weightVariant] || 400;
};

const typeLetterSpacing = (variant: TypographyVarient, breakpoint?: ThemeBreakpoints) => {
  if (breakpoint === ThemeBreakpoints.md) {
    return typographyMDObj.letterSpacing[variant] || "0em";
  }
  return typographyXSObj.letterSpacing[variant] || "0em";
};

export const StyledDynamicTypography = styled.p<CustomTypographyProps>`
  ${space};
  ${flex};
  ${color};
  ${typography};
  font-size: ${(props) => typeVariant(props.varient as TypographyVarient)};
  font-weight: ${(props) => typeWeightVariant(props.weightVarient as TypographyWeightVarient)};
  font-family: "Figtree";
  line-height: ${(props) => typeLineHeight(props.varient as TypographyVarient)};
  letter-spacing: ${(props) => typeLetterSpacing(props.varient as TypographyVarient)};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${(props) => typeVariant(props.varient as TypographyVarient, ThemeBreakpoints.md)};
    line-height: ${(props) =>
    typeLineHeight(props.varient as TypographyVarient, ThemeBreakpoints.md)};
    letter-spacing: ${(props) =>
    typeLetterSpacing(props.varient as TypographyVarient, ThemeBreakpoints.md)};
  }
`;

export const StyledDynamicBreakpointTypography = styled.p<CustomTypographyProps>`
  ${space};
  ${flex};
  ${color};
  ${typography};
  font-size: ${(props) => typeVariant(props.varient as TypographyVarient)};
  font-weight: ${(props) => typeWeightVariant(props.weightVarient as TypographyWeightVarient)};
  font-family: "Figtree";
  line-height: ${(props) => typeLineHeight(props.varient as TypographyVarient)};
  letter-spacing: ${(props) => typeLetterSpacing(props.varient as TypographyVarient)};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${(props) => typeVariant(props.mdVarient as TypographyVarient)};
    font-weight: ${(props) => typeWeightVariant(props.mdWeightVarient as TypographyWeightVarient)};
    line-height: ${(props) => typeLineHeight(props.mdVarient as TypographyVarient)};
    letter-spacing: ${(props) => typeLetterSpacing(props.mdVarient as TypographyVarient)};

    font-size: ${(props) => typeVariant(props.mdVarient as TypographyVarient, ThemeBreakpoints.md)};
    line-height: ${(props) =>
    typeLineHeight(props.mdVarient as TypographyVarient, ThemeBreakpoints.md)};
    letter-spacing: ${(props) =>
    typeLetterSpacing(props.mdVarient as TypographyVarient, ThemeBreakpoints.md)};
  }
`;
