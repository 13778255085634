import Link from "next/link";
import styled from "styled-components";

import Box from "@/design-system/Box";
import GridContainer from "@/design-system/GridContainer";
import Typography from "@/design-system/Typography";

import Container from "@/components/CoursePage/BIM_ARCHI_V2/Container/Container";

import { theme } from "@/theme";

const OuterBoxStyle = styled.section`
  background-color: rgba(255, 255, 255, 0.1);
  padding-top: 24px;
  padding-bottom: 24px;
`;
const LeftItem = styled.div`
  display: flex;
  align-items: center;
  grid-column: span 12;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: span 8;
  }
`;
const RightItem = styled.div`
  grid-column: span 12;
  display: flex;
  justify-content: start;
  margin-top: 16px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 0;
    grid-column: span 4;
    justify-content: flex-end;
  }
`;

const AndCopytypography = styled(Typography)`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;
const AndCopytypographyMd = styled(Typography)`
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const LinkTypo = styled(Typography)`
  &:hover {
    color: ${theme.colors.gray[900]};
  }
`;

interface FooterInfoBarInterface {
  isRow?: boolean;
}
const FooterInfoBar = ({ isRow }: FooterInfoBarInterface) => {
  return (
    <OuterBoxStyle>
      <Container>
        <GridContainer alignItems={"center"}>
          <LeftItem>
            <Link className="link__" href={"/privacy-policy"}>
              <LinkTypo varient="bodys" weightVarient="semibold" color={"gray.300"} mr={"24px"}>
                Privacy Policy
              </LinkTypo>
            </Link>
            <Box mr={"24px"} width={"1px"} height={"12px"} bg={"gray.300"} />
            <Link className="link__" href={"/terms-and-conditions"}>
              <LinkTypo varient="bodys" weightVarient="semibold" color={"gray.300"} mr={"24px"}>
                Terms of Use
              </LinkTypo>
            </Link>

            {!isRow && (
              <AndCopytypography
                color={"gray.300"}
                varient="captions"
                weightVarient="medium"
                mr={"24px"}
              >
                ©2023 Oneistox India Pvt. Ltd.{" "}
              </AndCopytypography>
            )}
          </LeftItem>

          <RightItem>
            {!isRow && (
              <AndCopytypographyMd varient="captions" mr={"41px"} color={"gray.300"}>
                ©2023 Oneistox India Pvt. Ltd.{" "}
              </AndCopytypographyMd>
            )}
            <Typography varient="captions" color={"gray.300"}>
              All Rights Reserved
            </Typography>
          </RightItem>
        </GridContainer>
      </Container>
    </OuterBoxStyle>
  );
};

export default FooterInfoBar;
